<template>
  <div>
    <!-- download92724CDBA550E8FEE1F2624C6DB09BB3.swf -->
    <!-- pic85F17BEA32EFC4BD80F7F0C8AC012CC2.jpg -->
    <img class="rounded img-large" src="">
    <p>
      <b>By Jim Stigall, Senior Concept Artist </b>
    </p>
    <p>
      We asked the NetDevil artists to tell us about their designs. Here's what they had to say:
    </p>
    <p>
      <b>What were the inspirations behind this image? </b>
    </p>
    <p>
      <i>The major inspiration was just to feel out the look of the player experience of performing a quick build in LEGO Universe in a fun and action-packed way. </i>
    </p>
    <p>
      <b>What's your favorite thing about this piece?</b>
    </p>
    <p>
      <i>The robot kind of looks like he’s dancin’!</i>
    </p>
    <p>
      <b>What's happening in the image? </b>
    </p>
    <p>
      <i> A minifig (representing any given player) uses the quick build ('tossing' bricks and having them click right into place) to create a cool robot.</i>
    </p>
    <p>
      <b>Anything else we ought to know?</b>
    </p>
    <p>
      <i> The robot in this drawing was referenced from an actual LEGO® Creator set.</i>
    </p>
    <p>
      <font size="1"><br>Please note: All of these concept art creations are part of the creative process. There is no guarantee that they will be in the game.</font>
    </p>
  </div>
</template>
